<template>
	<div class="sld_language_list">
		<MemberTitle :memberTitle="L['收货地址']"></MemberTitle>
		
		<div class="lang_default_con">
			<el-radio-group v-model="lang" fill="red">
			  <el-radio label="zh">{{ L['中文'] }}</el-radio>
			  <el-radio label="ru">{{ L['俄罗斯语'] }}</el-radio>
			</el-radio-group>
		</div>
			
		<div class="memLang_save">
		  <el-button @click="changeLanguage">{{ L['保存'] }}</el-button>
		</div>
	</div>
</template>
<script>
import { reactive, onMounted, getCurrentInstance, ref ,inject} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MemberTitle from '../../components/MemberTitle'
import {getCurLanguage} from '../../utils/common'
export default {
	name: 'memberLanguage',
	components: {
		MemberTitle
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const { proxy } = getCurrentInstance()
		const L = proxy.$getCurLanguage()
		const lang = ref('') 
		
		const reload = inject('reload')
		const changeLanguage = (params) => {
		  localStorage.setItem('languageSet',lang.value)
		  getCurLanguage()
		  reload()
		}
		onMounted(() => {
			lang.value = localStorage.getItem('languageSet') || 'ru'
		})

		return {
			L,
			lang,
			changeLanguage
		}
	}
}
</script>
<style lang="scss" >
.el-radio__input.is-checked .el-radio__inner {
    border-color: var(--color_main);
    background: var(--color_main_bg);
}
.el-radio__input.is-checked + .el-radio__label {
    color: var(--color_main);
}
.el-button:focus,
.el-button:hover {
    background: var(--color_main_bg);
    border-color: var(--color_main);
    color: #fff;
}
.sld_language_list{
	border: 1px solid #eeeeee;
	width: 1007px;
	margin: 10px 0 0 10px;
	float: left;
	background-color: white;
	padding-bottom: 100px;
}

.lang_default_con{
	padding: 22px;
}
.memLang_save {
	padding-left: 20px;
	margin-bottom: 50px;
	button {
		background-color: var(--color_main);
		color: #fff;
		border-color: var(--color_main);
	}
}
</style>
